import {
  getMenu,
  getPage,
  saveQuestions,
  getSteps,
  uploadFiles,
  removeFile,
} from '@/controllers/page'
import { errorHandler } from '@/utils/errorHandler'

export default {
  namespaced: true,
  state: {
    menu: [],
    activeMenu: {},
    questionaire: {},
    questions: [],
    activeStepIndex: 0,
    steps: [],
  },
  mutations: {
    // setMainTitle(state, { title = '', description = '' }) {
    //   state.title = title
    //   state.description = description
    // },
    setMenu(state, menu) {
      state.menu = menu
    },
    setActiveMenu(state, menu = state.menu[0]?.id) {
      if (!menu) return
      state.activeMenu = state.menu.filter((item) => item.id === menu)[0]
    },
    setSteps(state, steps) {
      state.steps = steps
    },
    setPage(state, { page, key }) {
      state[key] = page
    },
    setQuestionairePage(state, { page, key }) {
      state.questionaire[key] = page
    },
    setQuestions(state, questions) {
      state.questions = questions
    },
    setActiveStepIndex(state, step) {
      state.activeStepIndex = step
        ? state.steps.findIndex((s) => step.id == s.id)
        : null
    },
  },
  actions: {
    async loadMenu({ commit }) {
      const menu = await getMenu()
      if (!menu.data && menu.error_message == 'Menu items are not found')
        return false
      commit(
        'setMenu',
        [...menu.data].sort((prev, next) => prev.sort - next.sort)
      )
      return true
    },
    async loadSteps(ctx) {
      const steps = await getSteps(
        ctx.state.activeMenu?.id || ctx.state.menu[0]?.id
      )
      ctx.commit(
        'setSteps',
        steps.sort((prev, next) => prev.sort - next.sort)
      )
    },
    async loadQuestionaire(ctx, { page /* , name */ }) {
      try {
        const id = ctx.state.activeMenu?.id
        const content = await getPage({ id, page })

        const {
          questions,
          page_id,
          page_name,
          sort,
          intermediate_save,
          description,
        } = content.data.data
        ctx.commit('setQuestions', questions)
        ctx.commit('setQuestionairePage', {
          page: { page_id, page_name, sort, description, intermediate_save },
          key: page_id,
        })
        ctx.commit('setActiveStepIndex', { id: page_id, name: page_name, sort })
      } catch (e) {
        ctx.commit(
          'showNotification',
          {
            message: 'Произошла ошибка при загрузке анкеты',
            type: 'error',
          },
          {
            root: true,
          }
        )
      }
    },
    updateQuestion(ctx, { question, value, answer_id }) {
      const questionsState = ctx.state.questions || []

      function updateNode(questions) {
        questions.forEach((q) => {
          if (q.id === question.id) {
            q.value = value
            if (answer_id) q.answer_id = answer_id
          } else if (q.subquestions && Array.isArray(q.subquestions)) {
            updateNode(q.subquestions)
          } else if (q.subquestions && typeof q.subquestions == 'object') {
            updateNode(Object.values(q.subquestions))
          } else if (Array.isArray(q)) {
            q.forEach((s) => {
              if (s.subquestions) {
                s.subquestions.forEach((i) => {
                  if (
                    i.id == question.id &&
                    i.sub_group_num == question.sub_group_num
                  ) {
                    i.value = value
                    if (answer_id) i.answer_id = answer_id
                  }
                })
              }
              if (
                s.id == question.id &&
                s.sub_group_num == question.sub_group_num
              ) {
                s.value = value
                if (answer_id) s.answer_id = answer_id
              }
            })
          }
        })
      }

      updateNode(questionsState)

      ctx.commit('setQuestions', questionsState)
    },
    addItemToGroup(ctx, { items, id }) {
      const questionsState = ctx.state.questions

      function updateNode(arr) {
        arr.forEach((n) => {
          if (n.id == id) n.subquestions.push(items)
        })
      }

      updateNode(questionsState)
    },
    async uploadFiles(ctx, data) {
      ctx.commit('loading', true, { root: true })

      try {
        const resp = await uploadFiles(data)

        if (resp.status == 'ok' && resp.data) {
          const value = resp.data.map(({ doc_id, answer_id }, i) => ({
            id: answer_id,
            sub_group_num: '0',
            value: {
              size: data.files[i].size,
              get: `className=questionnaire&action=get_document&id=${doc_id}&file_request=true`,
              id: doc_id,
              name: data.files[i].name,
            },
          }))
          const answer_id = resp.data.map(({ answer_id }) => answer_id)

          await ctx.dispatch('updateQuestion', {
            question: data.question,
            value: data.question.value
              ? [...value, ...data.question.value]
              : value,
            answer_id: data.question.answer_id
              ? [...answer_id, ...data.question.answer_id]
              : answer_id,
          })
        } else if (resp.status == 'error') {
          const msg =
            resp.error_message == "Files didn't attach"
              ? 'Произошла ошибка при загрузке файла. Попробуйте уменьшить размер файла'
              : resp.error_message
          errorHandler(resp.error_message, null, msg)
        }

        return resp
      } catch (e) {
        errorHandler(e)
      } finally {
        ctx.commit('loading', false, { root: true })
      }
    },
    async removeFile(ctx, data) {
      const page_id = ctx.state.steps[ctx.state.activeStepIndex].id
      // const questionsState = ctx.state.questions

      ctx.commit('loading', true, { root: true })

      // questionsState.forEach(async (question) => {
      //   if (Array.isArray(question.value)) {
      //     question.value.forEach((file) => {
      //       if (file.id == id) {
      //         file.delete = true
      //       }
      //     })

      try {
        const resp = await removeFile({
          page_id,
          question_id: data.id,
          answer_id: data.file.id,
          doc_id: data.file.value.id,
        })
        return resp
        // clean previews, files, answer_id, question value
        // await saveQuestions({ questions: [question], page_num: Date.now() })

        // question.value = question.value.filter((file) => {
        //   return file.id != id
        // })
      } catch (e) {
        errorHandler(e)
      } finally {
        ctx.commit('loading', false, { root: true })
      }
      //   }
      // })
    },
    async removeItemFromGroup(ctx, { index, item }) {
      const questionsState = ctx.state.questions
      const { id, sort } = ctx.state.steps[ctx.state.activeStepIndex]

      questionsState.forEach((node) => {
        if (
          node.question_type == 'multigroup' &&
          node.subquestions &&
          node.subquestions[0][0].parent == item[0].parent
        ) {
          node.subquestions.forEach((sub, i) => {
            if (i === index) {
              sub.forEach((n) => {
                n.value = null
                n.delete = true
                n.additional = false

                if (n.subquestions) {
                  n.subquestions.forEach((s) => {
                    s.value = null
                    s.delete = true
                    s.additional = false
                  })
                }
              })
            }
          })

          const questions = node.subquestions.filter(
            (n) => n[0].delete && n[0].answer_id
          )

          try {
            saveQuestions({ questions, page_id: id, page_num: sort })
          } catch (e) {
            errorHandler(e)
          }

          node.subquestions = node.subquestions.filter((n, i) => {
            return i !== index
          })
        }
      })
    },
  },
}
