<template>
  <div :class="[$attrs.required && 'is-required']">
    <input
      v-bind="$attrs"
      :id="id"
      :data-val="val ? '1' : ''"
      :value="val ? '1' : ''"
      v-model="val"
      type="checkbox"
      v-on="{ ...$listeners, input: () => {} }"
    />
    <label :for="id" :class="['field-label', invalid && 'error']">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    val: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  font-size: 16px;
  line-height: 24px;
  color: $black-200;
  position: relative;
  padding-left: 30px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    // margin: auto;
    width: 20px;
    height: 20px;
    outline: none;
    background-color: transparent;
    background-image: url('../../assets/img/checkbox_square.svg');
  }

  &:not(.error):not(:checked) ~ label::before {
    background-image: url('../../assets/img/checkbox-hover_square.svg');
  }
}

input {
  position: absolute;
  left: -99999px;
  cursor: pointer;

  &:checked ~ label:before {
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-image: url('../../assets/img/checkbox-checked_square.svg');
  }

  &:checked:hover ~ label:before {
    background-image: url('../../assets/img/checkbox-checked-hover_square.svg');
  }

  &:not(:checked):hover ~ label:not(.error):before {
    background-image: url('../../assets/img/checkbox-hover_square.svg');
  }
}

.error::before {
  background-image: url('../../assets/img/checkbox_red-square.svg');
}
</style>
