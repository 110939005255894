<template>
  <div class="wrap">
    <div v-if="$slots.label" class="label-wrapper">
      <label :for="id" class="label">
        <slot name="label"></slot>
      </label>
    </div>
    <div class="field">
      <el-popover
        v-model="modelForDate"
        :disabled="!tooltip"
        :trigger="type != 'date' ? 'focus' : 'click'"
        placement="top"
        popper-class="tooltip"
      >
        <div v-html="tooltip"></div>
        <el-date-picker
          v-if="type == 'date'"
          v-model="correctDate"
          v-bind="$attrs"
          slot="reference"
          type="date"
          size="big"
          :id="id"
          :format="dateFormat"
          :clearable="false"
          :value-format="dateFormat"
          :picker-options="pickerOptions"
          :class="[
            'input',
            invalid && 'error',
            $attrs.required && 'is-required',
          ]"
          @change="onInput"
          @blur="modelForDate = false"
        />
        <el-input
          v-else-if="type != 'date' && mask"
          v-model="value"
          v-bind="$attrs"
          v-mask="mask"
          slot="reference"
          :type="type"
          :id="id"
          :minlength="limit"
          :maxlength="limit"
          size="big"
          :class="['input', invalid && 'error']"
          @change.native="(e) => onInput(e.target.value)"
          @input.native="(e) => onInput(e.target.value)"
        />
        <el-input
          v-else-if="type != 'date' && !mask"
          v-model="value"
          v-bind="$attrs"
          slot="reference"
          :type="type"
          :id="id"
          :minlength="limit"
          :maxlength="limit ? limit : '100'"
          size="big"
          :class="['input', invalid && 'error']"
          @change.native="(e) => onInput(e.target.value)"
          @input.native="(e) => onInput(e.target.value)"
        />
        <!-- <p class="error-message">Поле обязательно для заполнения</p> -->
      </el-popover>
    </div>
    <div v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { Input, DatePicker } from 'element-ui'
import { Popover } from 'element-ui'
import '@/assets/css/date-picker.css'

export default {
  name: 'TextInput',
  directives: { mask },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: [String, Number],
      default: 'text-input',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: null,
    },
    tooltip: String,
    limit: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      dateFormat: 'dd.MM.yyyy',
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      correctDate: this.value || '',
      modelForDate: false,
    }
  },
  mounted() {
    if (this.type === 'date' && this.value?.indexOf('-') >= 0) {
      const [a, b, c] = this.value.split('-')
      this.correctDate = `${c}.${b}.${a}`
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    focus() {
      this.$nextTick(() => (this.modelForDate = true))
    },
  },
  components: {
    'el-input': Input,
    'el-date-picker': DatePicker,
    'el-popover': Popover,
  },
}
</script>

<style lang="scss">
.el-input {
  font-family: Roboto, 'Roboto', sans-serif;
  position: relative;

  &.big,
  &--big .el-input__inner,
  &.big .el-input__inner {
    height: 40px;
    line-height: 40px;
  }

  &__prefix {
    left: 16px;
  }

  &--prefix:not(.el-date-editor) .el-input__inner {
    padding-left: 44px;
    line-height: 24px;
  }

  &--suffix:not(.el-date-editor) .el-input__inner {
    padding-right: 36px;
  }

  &__inner {
    border-radius: 4px;
    color: $black-200;
    font-size: 16px;
    padding: 0 10px 0 12px;
    border: 1px solid $black-30;
    box-shadow: none;
    outline: none;
    width: 100%;

    &::placeholder {
      color: $black-40;
      font-size: 16px;
      line-height: 24px;
    }

    &:hover {
      background: $black-10;
    }

    &:focus {
      border-color: $blue-120;
    }
  }

  &__suffix,
  &__prefix {
    line-height: 1;
  }

  &.is-disabled {
    opacity: 1 !important;

    .el-input__inner {
      background-color: $black-10;
      color: $black-80;
    }
  }
}

// .submitted {
//   .input {
//     &:invalid,
//     &:focus:invalid {
//       & + .error-message {
//         opacity: 1;
//         visibility: visible;
//       }
//     }
//   }
// }
</style>

<style lang="scss" scoped>
// .error-message {
//   font-size: 12px;
//   line-height: 14px;
//   color: $red-700;
//   opacity: 0;
//   visibility: hidden;
//   transition: 200ms all;
//   margin-top: 6px;
// }

.el-date-editor ::v-deep {
  width: 100%;

  &.el-input {
    &__inner {
      padding: 0 10px 0 16px;
    }

    &__prefix {
      left: 16px;

      .el-icon-date,
      .el-input__icon {
        display: none;
      }
    }

    &__suffix .el-input__icon:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      background-image: url('../../assets/img/calendar-blue.svg');
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      right: 4px;
      margin-top: -12px;
    }

    &--big .el-input__inner {
      height: 40px;
    }

    &--middle .el-input__inner {
      height: 32px;
    }

    &--small .el-input__inner {
      height: 24px;
    }

    input {
      padding-left: 12px !important;

      & + .el-input__prefix {
        left: 0 !important;
      }
    }
  }
}

.error ::v-deep .el-input__inner {
  border: 1px solid $red-700;
}

.error ::v-deep .el-input__suffix .el-input__icon:before {
  background-image: url('../../assets/img/calendar-red.svg');
}
</style>
